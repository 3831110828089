import {
  ApplicationFact,
  ApplicationFactOperator,
  CompanyDocumentType,
  RejectionReasonTag,
  RuleEngineCriteria,
  RuleValueType,
  SupportedCurrencies,
  User,
  UserQuery,
  UserRole,
} from 'core/types';
import {
  Application,
  ApplicationDetails,
  ApplicationStatus,
  OfferRate,
  OfferRevenueRepayment,
  OfferStatus,
} from 'store/applications/types';

export interface Admin extends User {
  role: UserRole.ADMIN;
}

export interface AdminState {
  loading: boolean;
  error: boolean | string;
  downloading: boolean;
  success: boolean | string;
  admins: Admin[];
  total: number;
  rulesEngine: RuleEngineRule[];
  refreshEmails: boolean;
}

export interface MakeRevenueBasedOfferDetails {
  valid_until: Date;
  revenue_repayments: OfferRevenueRepayment[];
}

export interface MakeBasicOfferDetails {
  min_credit_offer: number;
  max_credit_offer: number;
  duration_in_months: number;
  currency: SupportedCurrencies;
  valid_until: Date;
  rates: OfferRate[];
}

export interface MakeGenericOfferData {
  application_id: string;
  lender_id: string;
  status: OfferStatus;
  notes?: string;
  rejection_reason?: string;
  rejection_reason_tags?: RejectionReasonTag[];
}

export interface MakeRevenueBasedOfferData extends MakeGenericOfferData {
  product_type: OfferProductType.REVENUE_BASED | OfferProductType.BUSINESS_CREDIT_CARD;
  offer_details: MakeRevenueBasedOfferDetails;
  rejection_reason?: string;
  rejection_reason_tags?: RejectionReasonTag[];
}

export interface MakeBasicOfferData extends MakeGenericOfferData {
  product_type: OfferProductType.CREDIT_LINE | OfferProductType.REVOLVING_CREDIT_FACILITY | OfferProductType.TERM_LOAN;
  offer_details: MakeBasicOfferDetails;
}

export type MakeOfferData = MakeRevenueBasedOfferData | MakeBasicOfferData;

export interface UpdateOfferData extends Partial<MakeRevenueBasedOfferDetails & MakeBasicOfferDetails> {
  notes?: string;
  status?: OfferStatus;
  rejection_reason?: string;
  rejection_reason_tags?: RejectionReasonTag[];
}

export interface AdminReturnHook extends AdminState {
  makeAnOffer: (offer: MakeOfferData) => void;
  clearError: () => void;
  setError: (error: string | boolean) => void;
  updateApplicationStatus: (data: UpdateAppStatusData) => void;
  updateOfferDetails: (payload: { id: string; details: UpdateOfferData }) => void;
  updateOfferStatus: (data: UpdateOfferStatusData) => void;
  rejectApplication: (data: RejectAppData) => void;
  uploadLenders: (data: LenderUpload[]) => void;
  introduceLender: (payload: { id: string; lenderId: string; productType: OfferProductType }) => void;
  setSuccess: (success: string | boolean) => void;
  getAdminUsers: (query: UserQuery) => void;
  getRulesEngine: () => void;
  setRefreshEmails: (refresh: boolean) => void;
  updateRulesEngine: (rules: RuleEngineRule[]) => void;
  updateAdminUsers: (payload: string | Admin) => void;
}

export interface UpdateAppStatusData {
  id: string;
  appStatus: ApplicationStatus;
}

export interface Assignee {
  userId: string;
  applicationId: string;
}

export interface UpdateAppStatusPayload {
  applications?: Application[];
  applicationDetails?: ApplicationDetails;
}

export enum OfferProductType {
  REVENUE_BASED = 'REVENUE_BASED',
  CREDIT_LINE = 'CREDIT_LINE',
  REVOLVING_CREDIT_FACILITY = 'REVOLVING_CREDIT_FACILITY',
  TERM_LOAN = 'TERM_LOAN',
  BUSINESS_CREDIT_CARD = 'BUSINESS_CREDIT_CARD',
}

export enum OfferRepaymentTerms {
  WEEKLY_SALES = 'WEEKLY_SALES',
}

export enum MonthlyRepaymentType {
  INTEREST_ONLY = 'INTEREST_ONLY',
  INTEREST_AND_CAPITAL = 'INTEREST_AND_CAPITAL',
}

export interface LendersAction {
  name: string;
  displayed_name: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  active: boolean;
  address: string;
  logo_url: string;
  website: string;
}

export interface LenderUpload {
  name: string;
  displayed_name: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  active: boolean;
  address: string;
  logo_url: string;
  website: string;
}

export interface RejectAppData {
  id: string;
  rejectionReason?: string;
  rejectionReasonTags?: RejectionReasonTag[];
  send_at?: Date;
}

export interface UpdateOfferStatusData {
  id: string;
  status: OfferStatus;
  rejectionReason?: string;
  rejectionReasonTags?: RejectionReasonTag[];
}

export interface EmailData {
  email: string;
  cc?: string;
  bcc?: string;
  subject: string;
  body: string;
}

export interface EmailAttachment {
  id: string;
  type: CompanyDocumentType;
  name: string;
}

export interface Email {
  id: string;
  to: string;
  from?: string;
  subject: string;
  message: string;
  created_at: string;
  cc?: string;
  attachments?: EmailAttachment[];
}

export interface RuleEngineRule {
  id: string;
  lender_id?: string;
  lender_name?: string;
  parent_id?: string;
  fact: ApplicationFact;
  operator: ApplicationFactOperator;
  value: string | number | boolean;
  criteria?: RuleEngineCriteria;
  rules?: RuleEngineRule[];
  active: boolean;
  type: RuleValueType;
}

export interface CreateAdminUser {
  first_name: string;
  last_name: string;
  email: string;
  occupation?: string;
}
